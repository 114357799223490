import React, { useState, useEffect } from 'react';
import Nav from '../components/Nav'
import { Page_Container, ContentContainer, Title, Unit_Subtitle, Unit_Buttons, Unit_ButtonContainer, Unit_Button } from '../components/styles/PracticeSelectUnit.styled';


function App() {
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    fetch(`https://europe-west2-envodevedu.cloudfunctions.net/app/subjects`)
      .then(response => response.json())
      .then(data => setSubjects(data))
      .catch(error => console.log(error))
  }, []);

  return (
    <Page_Container>
      <Nav />
      <ContentContainer>
        <Title>Select Subject</Title>
        {
          subjects.map((subject) => {
            return <button key={subject.id} style={{}}>{subject.name}</button>
          })
        }
      </ContentContainer>
    </Page_Container>
  );
}

export default App;
