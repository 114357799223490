import styled from "styled-components";

export const Page_Container = styled.div`
  text-align: center;
  height: 100vh;
  background-color: #FFF;`

export const ContentContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 4rem);
  width: 100%;
  background-color: #282828;
  padding-top: 1.5rem;`

export const Title = styled.h1`
  font-size: 3rem;
  color: #fff;
  margin-bottom: 1rem;`

export const Questions = styled.div`
  height: 70vh;
  width: 70%;
  overflow-y: auto;`

export const QuestionContainer = styled.div`
  width: 100%;
  height: 3.5rem;
  background-color: #333;
  display: flex;
  padding-left: 10px;
  padding-top: 5px;
  justify-content: space-between;

  &:nth-child(odd) {
    background-color: #3c3c3c
  }`


export const QuestionText = styled.p`
  font-size: 1rem;
  text-align: left;
  color: #fff;`

export const AddButton = styled.button`
  font-size: 1.2rem;
  color: #fff;
  padding: 1rem 1.5rem;
  background-color: #272;
  border: none;
  border-radius: 1rem;
  font-weight: 700;
  cursor: pointer;`




export const ModalContainer = styled.div`
  position: absolute;
  left: calc(50% - 24rem);
  top: 10rem;
  width: 48rem;
  min-height: 26rem;
  background-color: #DDD;
  z-index: 10;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;`

export const ModalTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin: 1rem 0 0.5rem 0;`

export const ModalTextInput = styled.input`
  border: none;
  border-radius: 1rem;
  height: 2rem;
  width: 100%;
  text-align: center;
  font-size: 1rem;`

export const ActionButton = styled.button`
  width: 100%;
  height: 2.5rem;
  border: none;
  background-color: ${props => props.bg};
  color: #FFF;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;`

export const MultiChoice = styled.select`
  width: 10rem;
  height: 2rem;
  font-size: 1rem;
  text-align: center;
  border: none;
  border-radius: 10px;
  margin-bottom: 2rem;`

export const Checkboxes = styled.input.attrs({ type: 'checkbox' })`
  height: 1.5rem;
  width: 1.5rem;`

export const OptionsText = styled.input`
  height: 1.5rem;
  font-size: 1rem;
  width: 15rem;
  margin-right: 1rem;
  text-align: center;`