import logo from '../images/logo.svg';
import { Nav, NavLogo, NavItems, NavItem } from './styles/Nav.styled';

function NavComponent() {
    return (
        <Nav>
            <NavLogo alt='Logo' src={logo} />
            <NavItems>
                <NavItem>Item 1</NavItem>
                <NavItem>Item 2</NavItem>
                <NavItem>Item 3</NavItem>
                <NavItem>Item 4</NavItem>
            </NavItems>
        </Nav>
    );
}

export default NavComponent;
