import styled from "styled-components";

export const Page_Container = styled.div`
  text-align: center;
  height: 100vh;
  background-color: #282c34;`

export const ContentContainer = styled.main`
  width: 80%;
  margin: 0 auto;`

export const Title = styled.h1`
  font-size: 3.5rem;
  color: #FFFFFF;
  padding-top: 2rem;`

export const Subtitle = styled.h2`
  font-size: 1.6rem;
  color: #AAAAAA;
  font-weight: 400;
  margin-bottom: 3rem;`

export const Unit_Subtitle = styled.p`
  color: #FFF;
  font-weight: 700;
  font-size: 1.5rem;`

export const Unit_Buttons = styled.div`
  margin: 1rem 0 3rem 0;
  display: grid;
  row-gap: 0.3rem;
  grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);`

export const Unit_ButtonContainer = styled.div`
  position: relative;
  width: 95%;
  margin: 0 auto;`

export const Unit_Button = styled.button`
  display: block;
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  border-radius: 12px;
  border-color: transparent;
  background-color: #333842;
  color: #FFF;
  cursor: pointer;`

export const Button_StatusText = styled.p`
  color: #ddd;
  position: absolute;
  top: 4px;
  right: 10px;
  cursor: pointer;`

export const ProgressBar = styled.span`
  position: absolute;
  bottom: 2px;
  left: 10px;
  width: calc(100% - 20px);
  height: 4px;
  cursor: pointer;
  border-radius: 10px;
  z-index: ${props => props.z};
  background-color: ${props => props.bg};`