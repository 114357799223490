import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './css/index.css';

import PracticeSelectUnit from './pages/PracticeSelectUnit';
import PracticeSelectSubject from './pages/PracticeSelectSubject';
import Practice from './pages/Practice';
import InternalsHome from './pages/InternalsHome';
import InternalsQuestions from './pages/InternalsQuestions';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/practice/subjects" element={<PracticeSelectSubject />} />
            <Route path="/prac" element={<Practice />} />


            <Route path="/internals" element={<InternalsHome />} />
            <Route path="/internals/questions" element={<InternalsQuestions />} />
        </Routes>
    </BrowserRouter>
);