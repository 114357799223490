import Nav from '../components/Nav'

function App() {

  return (
    <div className="App">
      <Nav />
      <main>
        <h1 className='main_title'>Edexcel GCSE Mathematics</h1>
        <h2 className='main_subtitle'>Practice Questions</h2>
      </main>
    </div>
  );
}

export default App;
