import { useEffect, useState } from 'react';
import Nav from '../components/Nav';
import { Page_Container, ContentContainer, Panel, PanelTitle, AddButton, PanelScroll, PanelButton, ModalContainer, ModalTextInput, ActionButton, ModalTitle } from '../components/styles/InternalsHome.styled';
import { Link } from 'react-router-dom';

function InternalsHome() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [textInputModal, setTextInputModal] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState({});
  const [selectedUnit, setSelectedUnit] = useState({});


  useEffect(() => {
    fetch('https://europe-west2-envodevedu.cloudfunctions.net/app/internals/subjects')
      .then((response) => response.json())
      .then(data => setSubjects(data))
      .catch(error => {
        console.log(error)
        alert("Error!");
      })
  }, [])


  const addSubjectData = () => {
    let path = `internals/${selectedType}`;
    let requestBody;

    if (selectedType === 'subject') requestBody = { "name": textInputModal };
    else if (selectedType === 'unit') requestBody = { "name": textInputModal, "subject": selectedSubject.id }
    else if (selectedType === 'subunit') requestBody = { "name": textInputModal, "unit": selectedUnit.id, "subject": selectedSubject.id }


    if (textInputModal) {
      fetch(`https://europe-west2-envodevedu.cloudfunctions.net/app/${path}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      }).then((response) => response.json())
        .then(data => {
          switch (selectedType) {
            case 'subject':
              setSubjects(subjects.concat({ "id": data.id, "name": textInputModal, "units": [] }));
              break;

            case 'unit':
              let newSubjects = subjects;
              let subjectChange = (newSubjects[newSubjects.indexOf(selectedSubject)])["units"];
              subjectChange.push({ "id": data.id, "name": textInputModal, "subunits": [] });
              setSubjects(newSubjects);
              break;

            case 'subunit':
              let newSubjectsArr = subjects;
              const subjectIndex = newSubjectsArr.indexOf(selectedSubject);
              const unitIndex = newSubjectsArr[subjectIndex].units.indexOf(selectedUnit);
              let newSubunit = newSubjectsArr[subjectIndex].units[unitIndex].subunits;
              newSubunit.push({ "id": data.id, "name": textInputModal });
              newSubjectsArr[subjectIndex].units[unitIndex].subunits = newSubunit;
              setSubjects(newSubjectsArr);
              break;
          }
          setTextInputModal('');
        }).catch(error => {
          console.log(error);
          alert("Error!");
        })
      setShowAddModal(false);
    } else {
      alert("Add Input!");
    }
  }

  return (
    <Page_Container>
      <Nav />
      {showAddModal ? <AddModal setShowAddModal={setShowAddModal} addSubjectData={addSubjectData} textInputModal={textInputModal} setTextInputModal={setTextInputModal} selectedType={selectedType} setSelectedType={setSelectedType} /> : null}
      <ContentContainer>


        {/* SUBJECTS */}
        <Panel bg='#111'>
          <PanelTitle>Subjects</PanelTitle>
          <PanelScroll>
            {subjects.map((subject) => {
              return <PanelButton onClick={() => {
                setSelectedSubject(subject)
                setSelectedUnit({ id: "", name: "" });
              }} style={{ backgroundColor: selectedSubject.id === subject.id ? '#04B' : '#222' }} key={subject.id} bg='#222'>{subject.name}</PanelButton>
            })}
            <AddButton onClick={() => {
              setSelectedType('subject');
              setShowAddModal(true);
            }}>Add New</AddButton>
          </PanelScroll>
        </Panel>


        {/* UNITS */}
        <Panel bg='#181818'>
          <PanelTitle>Units</PanelTitle>
          <PanelScroll>
            {selectedSubject.units ? (
              selectedSubject.units.map((unit) => {
                return <PanelButton onClick={() => {
                  setSelectedUnit(unit);
                }} style={{ backgroundColor: selectedUnit.id === unit.id ? '#04B' : '#282828' }} key={unit.id} bg='#282828'>{unit.name}</PanelButton>
              })
            ) : null}

            {selectedSubject.id ? <AddButton onClick={() => {
              setSelectedType('unit');
              setShowAddModal(true);
            }}>Add New</AddButton> : null}

          </PanelScroll>
        </Panel>


        {/* SUBUNITS */}
        <Panel bg='#222'>
          <PanelTitle>Subunits</PanelTitle>
          <PanelScroll>
            {selectedUnit.subunits ? (
              selectedUnit.subunits.map((subunit) => {
                return <Link key={subunit.id} to={`/internals/questions?subunit=${subunit.id}`}><PanelButton bg='#333'>{subunit.name}</PanelButton></Link>
              })
            ) : null}

            {selectedUnit.id ? <AddButton onClick={() => {
              setSelectedType('subunit');
              setShowAddModal(true);
            }}>Add New</AddButton> : null}

          </PanelScroll>
        </Panel>
      </ContentContainer>
    </Page_Container>
  );
}
export default InternalsHome;




function AddModal({ setShowAddModal, addSubjectData, textInputModal, setTextInputModal, selectedType, setSelectedType }) {

  return (
    <ModalContainer>
      <div style={{ width: '100%' }}>
        <ModalTitle>Add {selectedType}</ModalTitle>
      </div>

      <div style={{ width: '100%' }}>
        <ModalTextInput value={textInputModal} onChange={(e) => setTextInputModal(e.target.value)} />
      </div>
      <div style={{ width: '100%' }}>
        <ActionButton onClick={() => {
          addSubjectData()
        }} bg='#272' style={{ borderBottomLeftRadius: '1rem' }}>Submit</ActionButton>
        <ActionButton onClick={() => {
          setShowAddModal(false)
          setTextInputModal('');
          setSelectedType('');
        }} bg='#A33' style={{ borderBottomRightRadius: '1rem' }}>Cancel</ActionButton>
      </div>
    </ModalContainer>
  )
}