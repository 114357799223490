import styled from "styled-components";

export const Nav = styled.nav`
    background-color: #1d2025;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15%;`

export const NavLogo = styled.img`
    height: 3.5rem;`

export const NavItems = styled.ul`
    color: white;
    list-style: none;`

export const NavItem = styled.li`
    display: inline-block;
    padding-right: 2.5rem;
    cursor: pointer;
    
    &:last-child {
        padding-right: 0;
    }`