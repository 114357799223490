import { useCallback, useEffect, useState } from 'react';
import Nav from '../components/Nav';
import { Page_Container, ContentContainer, Title, Questions, QuestionContainer, QuestionText, AddButton } from '../components/styles/InternalsQuestions.styled';
import { useLocation, useNavigate } from 'react-router-dom'
import AddModal from '../components/Internals/AddQuestionModal';

function InternalsQuestions() {
	const [subunitName, setSubunitName] = useState('');
	const [subunitId, setSubunitId] = useState('');
	const [questions, setQuestions] = useState([]);
	const [textInputQuestion, setTextInputQuestion] = useState('');
	const [textInputAnswer, setTextInputAnswer] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [questionArr, setQuestionArr] = useState([]);
	const [questionType, setQuestionType] = useState('text');


	{/*
	Check query params to ensure an subunit id is present.
	If the subunit is present, the questions will be fetched.
	If the subunit is not present, a redirect to the internal home page will be made.
	*/}
	const { search } = useLocation();
	const navigate = useNavigate();
	const URLProcessing = useCallback(
		() => {
			const URLParams = new URLSearchParams(search).get('subunit');
			if (!URLParams) {
				navigate("/internals");
			} else {
				setSubunitId(URLParams);
				fetch(`https://europe-west2-envodevedu.cloudfunctions.net/app/internals/questions?subunit=${URLParams}`)
					.then(response => response.json())
					.then(data => {
						setQuestions(data.questions);
						setSubunitName(data.name);
					})
					.catch(error => {
						console.log(error);
						alert("Error!");
					})
			}
		}, [search]
	);

	useEffect(() => {
		URLProcessing();
	}, [])


	{/* Send details for a new question to be added. */ }
	const addQuestion = () => {
		let bodyData;
		if (questionType === 'text') bodyData = { "type": "text", "subunit": subunitId, "question": textInputQuestion, "answer": textInputAnswer }
		if (questionType === 'multi') bodyData = { "type": "multi", "subunit": subunitId, "question": textInputQuestion, "choice": questionArr }

		fetch(`https://europe-west2-envodevedu.cloudfunctions.net/app/internals/questions`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(bodyData)
		}).then((response) => response.json())
			.then(data => {
				if (questionType === 'text') {
					setQuestions(questions.concat({ "type": "text", "id": data.id, "question": textInputQuestion, "answer": textInputAnswer }))
					setTextInputAnswer('');
				} else {
					setQuestions(questions.concat({ "type": "multi", "id": data.id, "question": textInputQuestion, "choice": questionArr }))
					setQuestionArr([]);
				}
				setTextInputQuestion('');
			}).catch(error => {
				console.log(error);
				alert("Error!");
			})
		setShowModal(false);
		setQuestionType('text');
	}


	return (
		<Page_Container>
			<Nav />
			{showModal ?
				<AddModal addQuestion={addQuestion} setShowModal={setShowModal} textInputQuestion={textInputQuestion} setTextInputQuestion={setTextInputQuestion} textInputAnswer={textInputAnswer} setTextInputAnswer={setTextInputAnswer} questionArr={questionArr} setQuestionArr={setQuestionArr} questionType={questionType} setQuestionType={setQuestionType} /> : null}
			<ContentContainer>
				<Title>{subunitName}</Title>
				<Questions>
					{/* Contains all current questions in the database. */}
					{questions.map((question) => {
						return <QuestionContainer key={question.id}>
							<div style={{ width: '96%' }}>
								<QuestionText>{question.question}</QuestionText>
							</div>
						</QuestionContainer>
					})}
				</Questions>
				<AddButton onClick={() => setShowModal(true)}>Add Question</AddButton>
			</ContentContainer>
		</Page_Container >
	);
}
export default InternalsQuestions;