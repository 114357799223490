import { ModalContainer, ModalTitle, ActionButton, ModalTextInput, MultiChoice, Checkboxes, OptionsText } from '../styles/InternalsQuestions.styled';

function AddModal({ addQuestion, setShowModal, textInputQuestion, setTextInputQuestion, textInputAnswer, setTextInputAnswer, questionArr, setQuestionArr, questionType, setQuestionType }) {

	return (
		<ModalContainer>
			<div style={{ width: '60%' }}>
				<ModalTitle>Add Question:</ModalTitle>
				<MultiChoice value={questionType} onChange={(e) => {
					setQuestionType(e.target.value);
					if (e.target.value === 'text') setQuestionArr([]);
					else setTextInputAnswer('');
				}}>
					<option value='text'>Text</option>
					<option value='multi'>Multiple Choice</option>
				</MultiChoice>

				{/* Question */}
				<ModalTextInput style={{ marginBottom: '0.5rem' }} placeholder='Question' value={textInputQuestion} onChange={(e) => setTextInputQuestion(e.target.value)} />

				{/* Answer */}
				{questionType === 'text' ? <ModalTextInput placeholder='Answer' value={textInputAnswer} onChange={(e) => setTextInputAnswer(e.target.value)} /> :
					<>
						<button style={{ marginBottom: '1rem', fontSize: '1rem', border: 'none', borderRadius: 10, padding: '0.6rem 0.8rem' }} onClick={() => setQuestionArr(questionArr.concat({ "qnum": questionArr.length, "correct": false, "textVal": '' }))}>Add new option</button>
						{questionArr.map(question => {
							return <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '0.2rem' }} key={question.qnum}>
								<OptionsText value={questionArr[question.qnum].textVal} onChange={(e) => {
									let newArr = questionArr.map(el => {
										if (el.qnum === question.qnum) return { ...el, textVal: e.target.value }
										else return el
									})
									setQuestionArr(newArr)
								}} placeholder='Option Text' />
								<Checkboxes checked={questionArr[question.qnum].correct} onChange={() => {
									let newArr = questionArr.map(el => {
										if (el.qnum === question.qnum) return { ...el, correct: !el.correct }
										else return el
									});
									setQuestionArr(newArr);
								}} />
							</div>
						})}
					</>
				}
			</div>

			{/* Bottom Action Buttons */}
			<div style={{ width: '100%' }}>
				<ActionButton onClick={() => addQuestion()} bg='#272'>Submit</ActionButton>
				<ActionButton onClick={() => {
					setTextInputQuestion('');
					setTextInputAnswer('');
					setQuestionArr([]);
					setShowModal(false);
					setQuestionType('text');
				}} bg='#A33' style={{ borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem' }}>Cancel</ActionButton>
			</div>
		</ModalContainer>
	)
}

export default AddModal;