import styled from "styled-components";

export const Page_Container = styled.div`
  text-align: center;
  height: 100vh;
  background-color: #FFF;`

export const ContentContainer = styled.main`
  display: flex;`

export const Panel = styled.div`
  background-color: ${props => props.bg};
  width: calc(100% / 3);
  height: calc(100vh - 4rem);
  position: relative;`

export const PanelTitle = styled.h2`
  font-size: 2.5rem;
  color: white;
  margin: 1rem 0 2rem 0;`

export const PanelScroll = styled.div`
  overflow-y: auto;
  height: 75vh;`

export const PanelButton = styled.button`
  height: 2.75rem;
  width: 100%;
  font-size: 1.5rem;
  background-color: ${props => props.bg};
  border: none;
  color: white;
  cursor: pointer;
  margin-bottom: 4px;`

export const AddButton = styled.button`
  height: 2.75rem;
  width: 100%;
  color: #fff;
  background-color: #262;
  border: none;
  margin-bottom: 4px;
  font-size: 1.5rem;
  font-weight: 700;
  cursor: pointer;`


export const ModalContainer = styled.div`
  position: absolute;
  left: 30%;
  top: 20%;
  width: 40%;
  height: 30%;
  background-color: #EEE;
  z-index: 10;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;`

export const ModalTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-top: 1rem;`

export const ModalTextInput = styled.input`
  border: none;
  border-radius: 1rem;
  height: 1.75rem;
  width: 20rem;
  text-align: center;
  font-size: 1.2rem;`

export const ActionButton = styled.button`
  width: 50%;
  height: 2.5rem;
  border: none;
  background-color: ${props => props.bg};
  color: #FFF;
  font-size: 1.2rem;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;`

export const ActionSelectors = styled.div`
  height: 2.5rem;
  width: 100%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: flex;`

export const ActionSelector = styled.button`
  height: 2.5rem;
  width: calc(100% / ${props => props.buttons});
  background-color: ${props => props.bg};
  border: none;
  font-size: 1.25rem;
  font-weight: 600;
  border-color: #555;
  border-width: 1px;
  cursor: pointer;`